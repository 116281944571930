import React, { useCallback, useEffect, useRef, useState } from 'react';
import CastModal from '../Modals/CastModal';
import CustomModal from '../UI/CustomModal';

import { useNeynarContext } from '@neynar/react';
import { CaretDown, CaretUp, GlobeHemisphereEast, MagnifyingGlass, Pulse, Translate } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { changeChannel, setNeynarSignerModal, setUserSelectedLanguage } from '../../features/globalStateSlice';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { checkSigner, getCurrentUserProfile, getFeedTopics, updateSigner } from '../../services/api';
import { LANGUAGES } from '../../utils/constants';
import SearchBar from '../SearchBar';
import Tabs from '../Tabs';


const TopHeader = ({ showTitle = true, PageTitle, tabs, showCastButton = false, selectedTab, onTabClick, ontoggle, showTabsInHeader, showFriendsOnly, tabPosition, showLanguageToggle = false }) => {

  const { pathname } = useLocation()
  const containerRef = useRef(null)
  const navigate = useNavigate()
  const { selectedChannel, notificationsNumber, feedSelectedTab, userSelectedLanguage } = useSelector((state) => state?.app)
  const dispatch = useDispatch()

  const [headerName, setHeaderName] = useState('')
  const [showCastModal, setShowCastModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectLanguageOpen, setSelectLanguageOpen] = useState(false);

  const { user } = useNeynarContext();


  useEffect(() => {
    if (pathname === '/') {
      setHeaderName('')
    } else if (pathname === '/tickets') {
      setHeaderName('Tickets')
    } else if (pathname === '/points') {
      setHeaderName('Points')
    } else if (pathname === '/ranking') {
      setHeaderName('Ranking')
    } else if (pathname === '/wallet') {
      setHeaderName('Wallet')
    } else if (pathname.includes('/profile')) {
      setHeaderName('Profile')
    }
    else if (pathname.includes('/tippingarena')) {
      setHeaderName('Tipping Arena')
    }
    else if (pathname.includes('/notifications')) {
      setHeaderName('Notifications')
    }
    else if (pathname.includes('/onchain')) {
      setHeaderName('Onchain')
    }
  }, [pathname])

  const { data: channelsData } = useQuery({
    queryKey: ['feedChannels'],
    queryFn: getFeedTopics,
    enabled: pathname === '/'
  });

  const { data: currUserProfileData } = useQuery({
    queryKey: ['getCurrentUserProfile'],
    queryFn: getCurrentUserProfile,
    enabled: pathname === '/'
  });


  const handleSignerValidate = useCallback(async () => {
    try {
      const response = await checkSigner();
      const isSigner = response.data?.success;

      if (isSigner) {
        dispatch(setNeynarSignerModal(false));
        return isSigner;
      }
      if (!isSigner && user?.signer_uuid) {
        const res = await updateSigner(user.signer_uuid);
        const isSignerUpdated = res.data?.success;
        return isSignerUpdated;
      } else {
        dispatch(setNeynarSignerModal(true));
        dispatch(displaySnackbar('Please set up your signer'));
        return false;
      }

    } catch (error) {
      console.error('Error checking or updating signer:', error);
      return false;
    }
  }, [dispatch, user]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenCastModal = async () => {
    const isValid = await handleSignerValidate();
    if (!isValid) return;
    setShowCastModal(true);
  }

  const handleCloseCastModal = () => {
    setShowCastModal(false)
  }

  const handleChannelSelect = (channel) => {
    dispatch(changeChannel(channel))
    setIsOpen(false)
    if (channel?.id === 'custom_global' || pathname.includes('/tippingarena')) return;
    navigate(`/channel/${channel?.id}`)
  }

  const handleLanguageSelect = (language) => {
    dispatch(setUserSelectedLanguage(language));
  }

  return (
    <div ref={containerRef} className={`h-[10vh] pt-5 relative bg-white dark:bg-[#121212] `}>
      {showTitle && <div className='justify-between items-center px-5 h-[60%] hidden md:flex'>
        <h2 className='prose-HeaderLarge text-primary'>{PageTitle}</h2>
        <div className='flex gap-4'>
          {showLanguageToggle && (
            <div className='h-8 relative w-fit px-4 dark:border-outline rounded-sm uppercase bg-gray-400 dark:bg-surface  flex justify-center items-center lg:w-fit font-bold text-xs leading-[18px] tracking-widest text-nowrap text-gray-200 dark:text-onBackgroundII hover:text-white gap-2' role='button' onClick={() => { setSelectLanguageOpen(prev => !prev) }}>
              <Translate size={16} /> {userSelectedLanguage === "off" ? "OFF" : userSelectedLanguage.slice(0, 2)}
              {selectLanguageOpen && (
                <div className='absolute top-10 left-0 w-auto flex flex-col z-50 gap-1 bg-gray-500 dark:bg-surface'>
                  {
                    LANGUAGES.map((language, index) => (
                      <div key={index} onClick={() => handleLanguageSelect(language)} className='   prose-LabelSmall cursor-pointer px-2 flex items-center'>
                        {language}
                      </div>
                    ))
                  }
                </div>
              )}
            </div>
          )}
          {showCastButton && <button onClick={handleOpenCastModal} className=' bg-primary text-white dark:text-onPrimary prose-TitleLarge px-10 h-8 rounded-sm custom-hover'>Cast</button>
          }
        </div>
      </div>}
      <div className='mb-3 sm:mb-0 flex h-6 sm:h-[unset] items-center justify-between mx-5'>
        <div className='flex items-center gap-4 grow w-full'>
          <div className="drawer-content flex flex-col items-center justify-center md:hidden">
            <label htmlFor="leftSideBar" className="drawer-button md:hidden">
              <img src={currUserProfileData?.data?.farcaster_user?.cdn_url || currUserProfileData?.data?.farcaster_user?.pfp_url || logo} alt="avatar" className="w-8 h-8 rounded-full" />
            </label>
          </div>
        </div>
        {(pathname.includes('/feed') || pathname.includes('/tippingarena')) && feedSelectedTab !== 'cards' &&
          <div data-intro='' data-step='1'
            className='relative block md:hidden grow w-full'>
            <div
              className={`intro-elem-channels h-9 transition-all duration-300 bg-gray-300 dark:bg-[#121214] rounded-full flex items-center prose-LabelLarge gap-1 px-4 py-2 justify-between cursor-pointer ${isOpen ? 'min-w-48' : 'min-w-40'}`}
              data-intro='Select Channel here'
              data-step='2'
              onClick={handleToggle}
            >
              <div className='w-4 h-4 rounded-full'>
                {
                  selectedChannel?.id === "custom_global" ? <GlobeHemisphereEast size={16} className=' fill-primary' /> :
                    <>{selectedChannel?.id === "custom_wild" ? <img className='w-4 h-4 rounded-full' src={logo} alt="" /> : <img className='w-4 h-4 rounded-full min-w-4' src={selectedChannel?.image_url} alt="" />}</>
                }
              </div>
              <p className='text-subTxtOnLightBg dark:text-onBackgroundII text-nowrap truncate'>
                {selectedChannel?.name}
              </p>
              {!isOpen ? <CaretDown size={16} className='fill-black dark:fill-white' /> : <CaretUp size={16} className='fill-black dark:fill-white' />}
            </div>

            {isOpen && (
              <div className='overflow-y-auto removeScrollBar max-h-[340px] absolute w-full mt-2 bg-gray-300 dark:bg-[#121214] rounded-sm shadow-lg z-[101] flex flex-col gap-1 pb-1'>
                <div className={`w-full prose-LabelSmall px-4 py-2 text-white hover:bg-gray-700 cursor-pointer flex items-center ${selectedChannel.id === 'custom_global' ? 'border-maintextColorOnLightBg dark:border-[#b4f6a5] border-[1px]' : ''}`}
                  onClick={() => handleChannelSelect({ id: 'custom_global', name: "Global" })}>
                  <span>
                    <GlobeHemisphereEast size={16} className='fill-black dark:fill-white' />
                  </span>
                  <span className='ml-1 text-nowrap text-txtOnLightBg dark:text-onBackground'>Global</span>
                </div>
                {channelsData?.data?.map((option, i) => (
                  <div
                    key={i}
                    className={`w-full prose-LabelSmall px-4 py-2 text-txtOnLightBg dark:text-white hover:bg-gray-700 cursor-pointer flex items-center ${selectedChannel?.name === option?.channel?.name ? 'border-maintextColorOnLightBg dark:border-[#b4f6a5] border-[1px]' : ''}`}
                    onClick={() => handleChannelSelect(option.channel)}
                  >
                    <span>
                      <img src={option?.channel?.image_url || logo} alt="channel" className='w-4 h-4 min-w-4 rounded-full' />
                    </span>
                    <span className='ml-1 text-nowrap text-txtOnLightBg dark:text-onBackgroundII'>{option?.channel?.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        }
        {!pathname.includes('/feed') && !pathname.includes('/tippingarena') && <div className='prose-HeaderLarge text-primary md:-translate-x-2 text-center flex md:hidden grow w-full justify-center text-nowrap'>
          {headerName}
        </div>
        }
        <div className='flex items-center gap-3 grow w-full justify-end'>
          {!pathname.includes('/onchain') && <span className='relative md:hidden'>
            <Pulse size={24} color='#ffffffa6' role='button' onClick={() => navigate('/onchain')} className='fill-black dark:fill-[#ffffffa6]' />
          </span>}
          <label htmlFor="rightSidebar" className="drawer-button md:hidden">
            <MagnifyingGlass size={24} className='fill-black dark:fill-[#ffffffa6]' />
          </label>
        </div>
      </div>


      <div className="drawer drawer-end z-50 ">
        <input id="rightSidebar" type="checkbox" className="drawer-toggle" />
        <div className="drawer-side">
          <label htmlFor="rightSidebar" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="md:p-6 w-full z-50 bg-white dark:bg-background min-h-full">
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </ul>
        </div>
      </div>

      {showTabsInHeader &&
        <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={onTabClick} onToggle={ontoggle} fullWidthTab={true} showFriendsOnly={showFriendsOnly} type={tabPosition} />
      }


      {showCastModal && <CustomModal isOpen={showCastModal} closeModal={handleCloseCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={"translate(-50%, -20%)"}>
        <CastModal closeModal={handleCloseCastModal} fromReplyCastModal={false} />
      </CustomModal>
      }
    </div>
  )
}

export default TopHeader