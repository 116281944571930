import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import logo from '../../../../assets/images/logo.png';
import axiosInstance from '../../../../lib/axiosInstance.js';
import { getCurrentUserProfile } from '../../../../services/api.js';
import MentionStyle from '../../styles/mentionsStyle.js';
import { useNavigate } from 'react-router-dom';

const renderMentionSuggestion = (suggestion, focused) => {
    return (
        <div className={`suggestion-item bg-surface text-primary prose-BodyLarge px-4 py-3 min-w-40 ${focused ? 'focused' : ''}`} key={suggestion?.id}>
            <div className="suggestion-content flex flex-row gap-3 justify-start items-center">
                <div>
                    <img src={suggestion?.pfp_url || suggestion?.cdn_url || logo} alt={suggestion?.display} fallback={logo} className="suggestion-avatar size-7 rounded-full cursor-pointer" />
                </div>
                <div className='flex flex-col'>
                    <span className='text-onBackground'>{suggestion?.name}</span>
                    <span className='prose-BodyMedium'>@{suggestion?.display}</span>
                </div>
            </div>
        </div>
    );
};

const renderChannelSuggestions = (suggestion, focused) => {
    return (
        <div className={`suggestion-item bg-surface text-primary prose-BodyLarge px-4 py-3 min-w-40 ${focused ? 'focused' : ''}`} key={suggestion?.id}>
            <div className="suggestion-content flex flex-row gap-3 justify-start items-center">
                <div>
                    <img src={suggestion?.pfp_url || suggestion?.cdn_url || logo} alt={suggestion?.display} fallback={logo} className="suggestion-avatar size-7 rounded-full cursor-pointer" />
                </div>
                <div className='flex flex-col'>
                    <span className='text-onBackground'>{suggestion?.name}</span>
                    <span className='prose-BodyMedium'>/{suggestion?.display}</span>
                </div>
            </div>
        </div>
    );
};

const CastInput = ({ inputRef, inputValue, setInputValue, fromReplyCastModal, castDetails }) => {

    const navigate = useNavigate()

    const [isUserSearch, setIsUserSearch] = useState(false)
    const [isChannelSearch, setIsChannelSearch] = useState(false)

    const { data: profileData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
        queryKey: ['getCurrentUserProfile'],
        queryFn: () => getCurrentUserProfile(),
    });

    const resizeTextarea = (e) => {
        if (e?.nativeEvent?.data === '/') {
            setIsChannelSearch(true)
            setIsUserSearch(false)
        } else if (e?.nativeEvent?.data === '@') {
            setIsUserSearch(true)
            setIsChannelSearch(false)
        } 
        const textarea = inputRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    useEffect(() => {
        resizeTextarea()
    }, [inputValue])

    const handleChange = (event, newValue) => {
        setInputValue(newValue);
    };

    const getUserMentionsAndChannel = useCallback((query, callback) => {
        if (!query) return
        const params = new URLSearchParams();
        if (isChannelSearch) {
            params.append('with_channel', 'true');
            params.append('with_user', 'false');
        }
        if (isUserSearch) {
            params.append('with_user', 'true');
            params.append('with_channel', 'false');
        }
        const queryString = params.toString();
        axiosInstance.get(`/app/search/v2?q=${query}&${queryString}`)
            .then(res => {
                if (isUserSearch) {
                    return (res?.data?.users?.slice(0, 6)?.map(item => {
                        const farcasterUser = item.farcaster_user;
                        return {
                            display: farcasterUser.username,
                            id: farcasterUser.fid,
                            name: farcasterUser.display_name,
                            pfp_url: farcasterUser.pfp_url,
                        };
                    }) || [])
                }
                if (isChannelSearch) {
                    return (res?.data?.channels?.slice(0, 6)?.map(item => {
                        return {
                            display: item.id,
                            id: item.id,
                            name: item.name,
                            url: item.url,
                            pfp_url: item.image_url,
                        };
                    }) || [])
                }
            }
            )
            .then(callback)
    }, [isChannelSearch, isUserSearch])

    const handleUserProfileNavigate = () => {
        navigate(`/profile/${castDetails?.farcaster_user?.username}`)
    }

    return (
        <>
            <div className='size-10 min-w-10 mr-2'>
                <img src={profileData?.data?.farcaster_user?.pfp_url || profileData?.data?.farcaster_user?.cdn_url || logo} alt='avatar' className='w-full rounded-full aspect-square shrink-0' />
            </div>
            <div
                className={`w-full border-2 border-transparent removeScrollBar ps-2 overflow-hidden`}
            >
                {fromReplyCastModal && <div className='prose-LabelMedium text-txtOnLightBg dark:text-onBackgroundII'>Replying to <span onClick={handleUserProfileNavigate} className='text-blue-500 hover:underline cursor-pointer'>@{castDetails?.farcaster_user?.username}</span></div>}
                <MentionsInput
                    onInput={e => {resizeTextarea(e)}}
                    className='bg-transparent z-100 prose-BodyLarge text-txtOnLightBg dark:text-onBackground pb-4 outline-none overflow-y-auto removeScrollBar'
                    value={inputValue}
                    a11ySuggestionsListLabel="User suggestions"
                    onChange={handleChange}
                    inputRef={inputRef}
                    placeholder='Time to go wild! Start typing here...'
                    style={MentionStyle}
                >
                    <Mention
                        // markup="@[__display__](__type__:__id__)"
                        displayTransform={(id, display) => `@${display}`}
                        trigger="@"
                        data={getUserMentionsAndChannel}
                        renderSuggestion={renderMentionSuggestion}
                        appendSpaceOnAdd={true}
                        className='mentions__mention'
                    />
                    <Mention
                        markup="/[__display__]"
                        displayTransform={(id, display) => `/${display}`}
                        trigger="/"
                        data={getUserMentionsAndChannel}
                        renderSuggestion={renderChannelSuggestions}
                        appendSpaceOnAdd={true}
                        className='mentions__mention'
                    />
                </MentionsInput>
            </div>
        </>
    )
}

export default CastInput;