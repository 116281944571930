import React, { useEffect, useRef, useState } from 'react'
import Icons from '../../utils/icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { isDesktopDevice } from '../../utils/helper';

import { useSelector } from 'react-redux';
import { getNotifications } from '../../services/api';
import { setNotificationsNumber } from '../../features/globalStateSlice';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { getSelectedCurrencyLocalStorage } from '../../utils/constants';


const _tabs = [
  { name: "feed", isActive: true, redirect: "/feed" },
  { name: "onchain", isActive: true, redirect: "/onchain" },
  { name: "notifications", isActive: false, redirect: "/notifications" },
  { name: "ticket", isActive: false, redirect: "/tickets" },
  // { name: "ranking", isActive: false, redirect: "/ranking" },
  { name: "points", isActive: false, redirect: "/points" },
  { name: "wallet", isActive: false, redirect: "/wallet" },
];

const getDataIntro = (name) => {
  switch (name) {
    case "feed": return "Your social feed, you can tip WILD on casts here.";
    case "notifications": return "Your notifications, you can tip WILD on casts here.";
    // case "activity": return "You can buy / sell tickets of any profile. 25% of the tips received by a profile are distributed among their ticket holders.";
    case "tickets": return "Tickets are priced on a bonding curve, which behaves like a meme coin at higher marker caps. Discover tickets to buy here.";
    case "points": return "You get points when you or the profiles of which you hold tickets get tips. You also get a daily allowance of WILD to tip."
    case "wallet": return "There is 2.5% share for you and the profile each on buy / sell of tickets. See your portfolio with pnl, ETH revenue here";
    default: return ""
  }
}

const BtmNavBar = () => {

  const { isCastModalOpen, notificationsNumber, feedSelectedTab, theme } = useSelector(state => state?.app);

  const dispatch = useDispatch();
  const { pathname } = useLocation()

  const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD'

  const { data: notifications } = useQuery({
    queryKey: ['notifications', 1],
    queryFn: () => getNotifications(1, 25, selectedCurrency),
    refetchInterval: 5000,
    enabled: !isCastModalOpen
  });

  useEffect(() => {
    const currNotifications = notifications?.data?.filter((notification) => !notification?.is_read)?.length || 0;
    dispatch(setNotificationsNumber(currNotifications));
  }, [notifications])

  const navigate = useNavigate();
  const tabNavRefs = useRef([]);


  const [tabs, setTabs] = useState(_tabs);
  const [underlineStyle, setUnderlineStyle] = useState({});

  const isDesktop = isDesktopDevice()

  const handleActiveTab = (name, redirect, event) => {
    const newTabs = tabs.map((tab) => {
      if (tab.name === name) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });
    setTabs(newTabs);
    if (event.metaKey || event.ctrlKey) {
      // Cmd or Ctrl key is pressed, open in a new tab
      window.open(redirect, '_blank');
    } else {
      // No modifier key, navigate as usual
      navigate(redirect);
    }
  };

  useEffect(() => {
    const newTabs = tabs.map((tab) => {
      if (pathname.startsWith(tab.redirect)) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });
    setTabs(newTabs);

    const activeTabIndex = tabs.findIndex(tab => pathname.startsWith(tab.redirect));
    if (activeTabIndex !== -1) {
      const activeTab = tabNavRefs.current[activeTabIndex];
      if (activeTab) {
        setUnderlineStyle({
          width: activeTab.offsetWidth,
          transform: `translateX(${activeTab.offsetLeft}px)`
        });
      }
    }
  }, [pathname]);

  return (
    <div className='z-[100] pb-5 bg-white dark:bg-background md:bg-transparent dark:md:bg-transparent border-t border-t-gray-800 md:border-none h-[70px] md-h-[90px] md:h-auto'>
      <div className='flex flex-row md:flex-col justify-around px-2 md:px-0'>
        {tabs.map((tab, idx) => (
          <button
            key={tab.name}
            ref={el => tabNavRefs.current[idx] = el}
            onClick={(event) => handleActiveTab(tab.name, tab.redirect, event)}
            className={`w-12 md:w-full md:px-2 rounded-sm ${idx == 1 ? 'hidden md:block' : ''}`}
            data-intro={getDataIntro(tab.name)}
            data-step={`${3 + idx + 1}`}
          >
            <div className='flex flex-col md:flex-row gap-2 items-center mt-2 md:mt-0 md:py-[10px]'>
              <span className='relative'>
                <Icons iconName={tab.name} isActive={tab.isActive} size={isDesktop ? 24 : 22} theme={theme}/>
                {(tab?.name == 'notifications') && notificationsNumber >= 1 && <span className='p-1 absolute -top-3 -right-2 bg-primary w-5 h-5 min-w-4 rounded-full text-[8px] font-semibold text-onPrimary flex items-center justify-center aspect-square'>{notificationsNumber <= 21 ? notificationsNumber : '21+'}</span>}
              </span>
              <p className={`${feedSelectedTab === 'cards' ? 'md:hidden' : ''} prose-BottomNav lg:prose-TitleLarge md:font-extrabold ${tab.isActive ? "text-btnColorOnLightBg dark:text-primary" : "text-[#434343] dark:text-onBackgroundIII"} uppercase text-nowrap truncate hidden md:flex`}>{tab.name}</p>
            </div>
          </button>
        ))}
        {
          tabs.some(tab => tab.isActive) &&
          <div className="absolute top-0 h-[3px] left-0 bg-primary transition-all duration-300 md:hidden" style={underlineStyle} />
        }
      </div>
    </div>
  )
}

export default BtmNavBar