import { Chat, Copy } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import powerBadge from '../../../assets/svg/powerbadge.svg';
import { setSelectedUserToMessage } from '../../../features/globalStateSlice';
import useBuyNFTTransaction from '../../../hooks/buySellNFT';
import useCastAction from '../../../hooks/signer';
import { getProfileDetailNew, getProfileHolders, getProfileTags } from '../../../services/api';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';
import { highlightWord } from '../../../utils/helper';
import BuyModal from '../../Modals/BuyModal';
import SellModal from '../../Modals/SellModal';
import CustomModal from '../../UI/CustomModal';
import ProfileDetailSkeleton from './ProfileDetailSkeleton';
import { displaySnackbar } from '../../../features/thunkMiddleware';

const ProfileDetails = ({ fromProfileModal, fid, profileHoldersRefetch, showHeader, netWorth, handleTabClick }) => {
    const navigate = useNavigate();
    const { username } = useParams();
    const dispatch = useDispatch();
    const { currentUser } = useSelector(state => state.app);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const { followUser, unFollowUser } = useCastAction();
    const [selectedBuy, setSelectedBuy] = useState({});
    const [selectedSell, setSelectedSell] = useState(null);
    const [showSellTicketModal, setShowSellTicketModal] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency
    const currentUsername = currentUser?.username;
    const { getUserMarketCap } = useBuyNFTTransaction();

    const [userMarketcap, setUserMartetcap] = useState({
         value: 0,
         isLoading: false
    })

    const { data: profileData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
        queryKey: ['userProfileNew', username, selectedCurrency],
        queryFn: () => getProfileDetailNew(username, selectedCurrency),
        enabled: !!username && !!selectedCurrency,
    });

    const { data: myFollowingHolder } = useQuery({
        queryKey: ['followingHolders', username],
        queryFn: () => getProfileHolders(username, true),
    });

    const { data: profileTags, isLoading: profileTagsLoading } = useQuery({
        queryKey: ['currentUserTags', fid],
        queryFn: () => getProfileTags(fid),
        enabled: fid ? true : false,
    });

    useEffect(() => {
        if (profileData?.data?.view_user_context?.follows) {
            setIsFollowing(true);
        } else {
            setIsFollowing(false);
        }
    }, [profileData]);

    useEffect(() => {
        setUserMartetcap({value: 0, isLoading: true})
        if(!profileData?.data?.profile?.fid) return
        getUserMarketCap(profileData?.data?.profile?.fid).then((res) => {
            setUserMartetcap({
                value: res,
                isLoading: false
            })
        })
    }, [profileData?.data?.profile?.fid])

    const handleBuyModal = (e) => {
        e.stopPropagation();
        setSelectedBuy({
            farcaster_user: {
                username: profileData?.data?.profile?.username,
                fid: profileData?.data?.profile?.fid,
                display_name: profileData?.data?.profile?.display_name,
                pfp_url: profileData?.data?.profile?.pfp_url,
            },
            num_tickets_owned_by_me: profileData?.data?.view_user_context?.num_tickets_owned?.value
        });
        setShowBuyModal(true);
    };
    const handleCloseBuyModal = () => {
        setShowBuyModal(false);
    };

    const handleOpenSellTicketModal = (e) => {
        e.stopPropagation();
        setSelectedSell({
            farcaster_user: {
                username: profileData?.data?.profile?.username,
                fid: profileData?.data?.profile?.fid,
                display_name: profileData?.data?.profile?.display_name,
                pfp_url: profileData?.data?.profile?.pfp_url,
            },
            num_tickets: profileData?.data?.view_user_context?.num_tickets_owned?.value
        });
        setShowSellTicketModal(true);
    };

    const handleCloseSellTicketModal = () => {
        setShowSellTicketModal(false);
    };

    const handleUserToMessage = () => {
        dispatch(setSelectedUserToMessage(profileData?.data));
        localStorage.setItem('selectedUserToMessage', JSON.stringify(profileData?.data));
        const profileFid = profileData?.data?.profile?.fid;
        const myFid = currentUser.fid;

        // Ensure the lower numeric value is listed first
        const [firstFid, secondFid] = profileFid < myFid ? [profileFid, myFid] : [myFid, profileFid];

        // navigate(`/chat/${firstFid}_${secondFid}`);
    };


    const handleFollow = async () => {
        const fid = profileData?.data?.profile?.fid;
        const isFollows = profileData?.data?.view_user_context?.follows;

        setIsFollowing((prev) => !prev);

        if (!isFollows) {
            const res = await followUser(fid);
            if (res?.data?.type === 'MESSAGE_TYPE_LINK_ADD') {
                profileRefetch();
            }
        } else {
            const res = await unFollowUser(fid);
            if (res?.data?.type === 'MESSAGE_TYPE_LINK_REMOVE') {
                profileRefetch();
            }
        }
    };

    const refetchAfterBuy = () => {
        profileHoldersRefetch();
        profileRefetch();
    }

    const handleCopyFID = () => {
        navigator.clipboard.writeText(profileData?.data?.profile?.fid);
        dispatch(displaySnackbar('FID copied to clipboard'))
    }

    if (profileDataLoading) return <ProfileDetailSkeleton />

    return (
        <>
            <div className="w-full flex flex-wrap justify-between items-start gap-[18px] order-1 mb-0 md:mb-6">
                <div className='flex gap-4 w-full items-start'>
                    <div className="md:w-[120px] md:max-w-[120px] w-12 max-w-12 aspect-square rounded-full">
                        <img src={profileData?.data?.profile?.pfp_url || profileData?.data?.profile?.cdn_url || logo} alt="profile image" className="w-full h-full object-contain aspect-square shrink-0 rounded-full" />
                    </div>
                    <div className='flex-1 h-full flex flex-col justify-start items-start gap-1 w-full'>
                        <div className='flex items-start w-full'>
                            <div className='grow'>
                                <div className='flex items-start gap-2 justify-between w-full'>
                                    <div className="flex items-center">
                                        <h1 className="relative inline-block prose-TitleLarge text-base md:font-['Bungee'] break-words text-wrap md:text-[24px] md:leading-[26.4px] w-full md:-tracking-[0.02em] text-txtOnLightBg dark:text-onBackground mb-1 md:mb-0 mr-6 min-h-5">
                                            {profileData?.data?.profile?.display_name}
                                            {profileData?.data?.profile?.power_badge && <span className="absolute inline-block mt-1 ml-1 size-4" style={{background: `url(${powerBadge}) no-repeat center center`, backgroundSize: 'contain' }}></span>}
                                        </h1>
                                    </div>
                                   {currentUsername !== username && <button onClick={handleFollow} className={`md:hidden prose-BodySmallBold md:prose-TitleLarge text-gray-100 dark:text-background bg-gray-500 dark:bg-onBackground px-2 flex items-center md:h-[39px] h-[22px]`}>{isFollowing ? 'Following' : 'Follow'}</button>}
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <p className='flex items-center gap-x-2 flex-wrap'>
                                        <span
                                            className='prose-BodyMedium md:prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII truncate'>@{profileData?.data?.profile?.username}
                                        </span>

                                        {profileData?.data?.follow_back &&
                                            <span className='prose-BottomSmall text-subTxtOnLightBg dark:text-onBackgroundII text-center px-2 md:h-[22px] h-4 flex items-center justify-center bg-gray-200 dark:bg-surface border dark:border-outlineII'>
                                                Follows you
                                            </span>
                                        }
                                    </p>
                                    {currentUsername !== username && <div className='flex items-center gap-1 flex-nowrap md:hidden'>
                                        {profileData?.data?.follow_back &&
                                            <button onClick={handleUserToMessage} className={`flex items-center justify-center text-black dark:text-onBackground border border-black dark:border-onBackgroundII size-6 min-w-6 rounded-full`}><Chat size={12} /></button>
                                        }
                                    </div>}
                                </div>
                            </div>
                            {currentUsername !== username &&
                                <div className='md:flex items-center gap-2 flex-wrap-reverse justify-end hidden'>
                                    <div className='flex items-center gap-2 w-fit'>
                                        {profileData?.data?.follow_back &&
                                            <button onClick={handleUserToMessage} className={`flex items-center justify-center text-black dark:text-onBackground border border-black dark:border-onBackgroundII size-8 min-w-8 rounded-full`}><Chat size={14} /></button>
                                        }
                                    </div>
                                    <button onClick={handleFollow} className={`prose-TitleLarge text-gray-100 dark:text-background bg-gray-500 dark:bg-onBackground px-2 flex items-center md:h-[39px] h-[22px]`}>{isFollowing ? 'Following' : 'Follow'}</button>
                                </div>
                            }
                        </div>
                        <div className='hidden md:block prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundIII  items-start w-full'>
                            {highlightWord(profileData?.data?.profile?.profile_bio, false, navigate)}
                        </div>
                        <div className='md:flex items-center min-h-[21px] gap-x-4 w-full flex-wrap mt-3 hidden'>
                            <p className='text-nowrap flex gap-1'>
                                <span className=' text-primary prose-BodyMediumBold'>{profileData?.data?.profile?.following_count_display?.display_value}</span>
                                <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII'> Following</span>
                            </p>
                            <p className='text-nowrap flex gap-1'>
                                <span className=' text-primary prose-BodyMediumBold'>{profileData?.data?.profile?.follower_count_display?.display_value}</span>
                                <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII'> Followers</span>
                            </p>
                            <p className='text-nowrap flex'>
                                <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII'> FID : </span>
                                <span className=' text-primary prose-BodyMediumBold'>{profileData?.data?.profile?.fid}</span>
                                <button className='ml-2' onClick={handleCopyFID}>
                                    <Copy size={16} className='fill-primary' />
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden mt-4 prose-BodyMediumBold text-[13px] text-subTxtOnLightBg dark:text-onBackgroundIII items-start w-full'>
                {highlightWord(profileData?.data?.profile?.profile_bio, false, navigate)}
            </div>

            {
                profileTagsLoading ? <div className='flex items-center gap-2 flex-wrap mt-2 mb-4'>

                    {Array.from({ length: 3 }).map((_, idx) => (
                        <div key={idx}  className='text-subTxtOnLightBg dark:text-onBackgroundII text-center px-3 bg-surface animate-pulse w-12 h-5'>
                        </div>
                    ))}
                </div> : <div className={`flex items-center gap-2 flex-wrap ${profileTags?.data?.values !== null?'mt-2 mb-4':''}`}>

                    {profileTags?.data?.values !== null && profileTags?.data?.values?.slice(0, 3).map((value, idx) => (
                        <span key={idx} className='prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII text-center px-3 bg-gray-200 dark:bg-surface border dark:border-outlineII h-[22px] flex items-center'>
                            {value}
                        </span>
                    ))}
                </div>

            }
            <div className='flex items-center min-h-[21px] gap-x-4 w-full flex-wrap mt-3 md:hidden prose-LabelMedium'>
                <p className='text-nowrap flex gap-1'>
                    <span className=' text-primary '>{profileData?.data?.profile?.following_count_display?.display_value}</span>
                    <span className=' text-subTxtOnLightBg dark:text-onBackgroundIII'> Following</span>
                </p>
                <p className='text-nowrap flex gap-1'>
                    <span className=' text-primary '>{profileData?.data?.profile?.follower_count_display?.display_value}</span>
                    <span className=' text-subTxtOnLightBg dark:text-onBackgroundIII'> Followers</span>
                </p>
                <p className='text-nowrap flex'>
                    <span className=' text-subTxtOnLightBg dark:text-onBackgroundIII'> FID : </span>
                    <span className=' text-primary '>{profileData?.data?.profile?.fid}</span>
                    <button className='ml-2' onClick={handleCopyFID}>
                        <Copy size={16} className='fill-primary' />
                    </button>
                </p>
            </div>
            {
                myFollowingHolder?.length > 0 &&
                <div className="mt-2 flex justify-start items-center prose-LabelSmall gap-2">
                    <div className='flex items-center self-start h-[42px]'>
                        {
                            myFollowingHolder.slice(0, 2).map((user, i) => {
                                return (<div key={i} className={`w-6 h-[24px] bg-red rounded-full ${i != 0 ? '-ml-2' : ''} `}>
                                    <img
                                        role='button'
                                        onClick={(e) => { navigate(`/profile/${user?.trader?.username}`) }}
                                        src={user?.trader?.pfp_url} alt="" className='w-full h-full object-cover rounded-full' />
                                </div>)
                            })
                        }
                    </div>
                    <p className='text-subTxtOnLightBg dark:text-onBackgroundIII'>
                        Ticket owned by {
                            myFollowingHolder.slice(0, 2).map((user, i) => {
                                return (
                                    <span key={i}
                                        role='button'
                                        onClick={() => { navigate(`/profile/${user?.trader?.username}`) }}
                                        className=' text-primary'>{user?.trader?.username}{
                                            i != myFollowingHolder.slice(0, 2).length - 1 ? ', ' : ''
                                        }
                                    </span>
                                )
                            })
                        }
                        {
                            myFollowingHolder.length > 3 && ` and ${myFollowingHolder.length - 2} more you know`
                        }
                    </p>
                </div>
            }
            <div className='md:hidden'>
                {profileDataLoading ?
                    <UserPointsSkeleton />
                    :
                    <div className='flex gap-4 mt-4 overflow-x-auto removeScrollBar'>
                        <div 
                        onClick={() => handleTabClick('portfolio')}
                        className='bg-gray-200 dark:bg-surface border border-outline h-[58px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2'>
                            <p className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundIII'>Net Worth</p>
                            <p className='prose-TitleLarge text-base text-txtOnLightBg dark:text-onBackground'>${netWorth}</p>
                        </div>
                        <div className='bg-gray-200 dark:bg-surface border border-outline h-[58px] min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2'>
                            <p className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundIII'>Market Cap</p>
                            <p className='prose-TitleLarge text-base text-txtOnLightBg dark:text-onBackground'>{userMarketcap?.isLoading ? <div className='bg-surface/80 animate-pulse h-6 w-12'/> : parseFloat(userMarketcap?.value).toFixed(2)}</p>
                        </div>
                        {/* <div className='bg-gray-200 dark:bg-surface border border-outline h-[58px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2'>
                            <p className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundIII'>Tips Received</p>
                            <p className='prose-TitleLarge text-base text-txtOnLightBg dark:text-onBackground'>{profileData?.data && profileData?.data?.earnings_24h?.distribution_values?.tips} {profileData?.data && profileData?.data?.earnings_24h?.currency}</p>
                        </div>
                        <div className='bg-gray-200 dark:bg-surface border border-outline h-[58px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2'>
                            <p className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundIII'>Top Holders</p>
                            <div className='prose-TitleLarge text-base text-txtOnLightBg dark:text-onBackground'>
                                <div className='h-[24px] flex items-center gap-1'>
                                    {
                                        profileHoldersLoading ? <>
                                            <div className='w-5 max-w-5 h-5 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                            <div className='w-5 max-w-5 h-5 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                            <div className='w-5 max-w-5 h-5 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                        </> :
                                            profileHolders?.length < 1 ? <p>0</p>
                                                : profileHolders?.slice(0, 3).map((holder, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            role='button'
                                                            onClick={() => navigate(`/profile/${holder?.trader?.username}`)}
                                                            className='w-5 max-w-5 h-5 aspect-square rounded-full'>
                                                            <img src={holder?.trader?.pfp_url || holder?.trader?.cdn_url} alt="holder" className='w-5 max-w-5 h-5 rounded-full' />
                                                        </button>
                                                    )
                                                })
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>
                }
            </div>

            <div className={`flex flex-col justify-center gap-3 mt-4 fixed ${showHeader ? 'translate-y-0' : 'md:translate-y-0 translate-y-[50px]'} transition duration-500 md:static bottom-20 z-20 left-0 right-0 mx-5 md:mx-0 items-center`}>
                <div className='flex flex-row md:flex-row justify-center gap-3 items-center w-full md:hidden'>
                    <button disabled={profileData?.data?.num_tickets_owned_by_me == 0} onClick={handleOpenSellTicketModal} className={`bg-gray-500 dark:bg-background border dark:border-primary font-[bungee] md:w-[140px] w-full text-gray-200 dark:text-onBackgroundII h-[42px] ${profileData?.data?.num_tickets_owned_by_me == 0 ? '' : 'custom-hover hover:text-white'}`}>Sell</button>
                    <button onClick={handleBuyModal} className='bg-maintextColorOnLightBg/90 hover: bg-primary dark:hover:bg-primary text-white dark:text-onPrimary font-[bungee] md:w-[140px] w-full h-[42px] custom-hover'>Buy Ticket</button>
                </div>
            </div>


            {fromProfileModal && <div className='flex gap-4 mt-5 overflow-x-auto removeScrollBar'>
                <div className='bg-surface border border-outline h-[73px] min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
                    <p className='prose-BodyMediumBold text-onBackgroundIII'>Market Cap</p>
                    <p className='prose-HeaderLarge text-onBackground'>{userMarketcap?.isLoading ? <div className='bg-surface animate-pulse h-4 w-10'/> : parseFloat(userMarketcap?.value).toFixed(2)}</p>
                </div>
                <div className='bg-surface border border-outline h-[73px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
                    <p className='prose-BodyMediumBold text-onBackgroundIII'>Holders</p>
                    <p className='prose-HeaderLarge text-onBackground'>{profileData?.data?.num_tickets / 1000}</p>
                </div>
                <div className='bg-surface border border-outline h-[73px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
                    <p className='prose-BodyMediumBold text-onBackgroundIII'>Points Balance</p>
                    <p className='prose-HeaderLarge text-onBackground'>{profileData?.data?.points_holdings?.display_value}</p>
                </div>
            </div>}

            {/* 
            {showTipModal && <CustomModal isOpen={showTipModal} closeModal={handleCloseTipModal} setShowTipModal={setShowTipModal} translatePosition={'translate(-50%, 0%)'}> 
                    <TipModal selectedTip={castData} closeModal={handleCloseTipModal} setShowTipModal={setShowTipModal} refetch={profileRefetch}/>
            </CustomModal>} */}

            {showBuyModal && <CustomModal isOpen={showBuyModal} closeModal={handleCloseBuyModal} setShowTipModal={setShowBuyModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                <BuyModal selectedBuy={selectedBuy} closeModal={handleCloseBuyModal} refetch={refetchAfterBuy} />
            </CustomModal>}

            {showSellTicketModal &&
                <CustomModal isOpen={showSellTicketModal} closeModal={handleCloseSellTicketModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <SellModal data={selectedSell} closeModal={handleCloseSellTicketModal} key={'1'} refetch={() => { }} />
                </CustomModal>
            }
        </>
    )
}

export default ProfileDetails


const UserPointsSkeleton = () => {
    return <div className='flex gap-4 mt-4 overflow-x-auto removeScrollBar px-5'>
        <div className='bg-surface/50 dark:bg-surface border border-outline h-[73px] min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
            <p className='prose-BodyMediumBold text-onBackgroundIII'>Market Cap</p>
            <p className='w-20 h-8 animate-pulse bg-background/60'></p>
        </div>
        <div className='bg-surface/50 dark:bg-surface border border-outline h-[73px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
            <p className='prose-BodyMediumBold text-onBackgroundIII'>Holders</p>
            <p className='w-20 h-8 animate-pulse bg-background/60'></p>
        </div>
        <div className='bg-surface/50 dark:bg-surface border border-outline h-[73px]  min-w-[130px] w-[150px] flex flex-col items-start justify-center ps-2 rounded-sm'>
            <p className='prose-BodyMediumBold text-onBackgroundIII'>Points Balance</p>
            <p className='w-20 h-8 animate-pulse bg-background/60'></p>
        </div>
    </div>
}
