import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedChannel: {
    name: "Global",
    id: 'custom_global'
  },
  snackBar: {
    text: '',
    show: false,
    cast: null,
    snackLink: null
  },
  selectedCastDetails: {},
  mainFeedBottomThreshold: false,
  currentUser: {},
  selectedUserToMessage: {},
  createdCast: {},
  selectedCurrency: 'WILD',
  openNeynarSignerModal: false,
  notificationsNumber: 0,
  feedSelectedTab: '',
  isCastModalOpen: false,
  userSelectedLanguage : 'english',
  theme: 'dark',
  isTippingMoxie: false,
}

export const globalStateSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    showSnackbar: (state, action) => {
      if(action.payload.cast){
        state.snackBar = {
          text: action.payload.message,
          show: true,
          cast: action.payload.cast,
          snackLink: null
        };
        return
      }
      if(action.payload.snackLink){
        state.snackBar = {
          text: action.payload.message,
          show: true,
          snackLink: action.payload.snackLink,
          cast: null
        };
        return
      }
      state.snackBar = {
        text: action.payload,
        show: true,
        cast: null,
        snackLink: null
      };
    },
    hideSnackbar: (state) => {
      state.snackBar = {
        text: '',
        cast: null,
        show: false,
        snackLink: null
      };
    },
    setSelectedCastDetails: (state, action) => {
      state.selectedCastDetails = action.payload
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setSelectedUserToMessage: (state, action) => {
      state.selectedUserToMessage = action.payload
    },
    setCreatedCast: (state, action) => {
      state.createdCast = action.payload
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload
    },
    setNeynarSignerModal: (state, action) => {
      state.openNeynarSignerModal = action.payload
    },
    setNotificationsNumber: (state, action) => {
      state.notificationsNumber = action.payload
    },
    setFeedSelectedTab: (state, action) => {
      state.feedSelectedTab = action.payload
    },
    setIsCastModalOpen: (state, action) => {
      state.isCastModalOpen = action.payload
    },
    setUserSelectedLanguage: (state, action) => {
      state.userSelectedLanguage = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setIsTippingMoxie: (state, action) => {
      state.isTippingMoxie = action.payload
    }
  }
});

export const { changeChannel, showSnackbar, hideSnackbar, setSelectedCastDetails, setCurrentUser, setSelectedUserToMessage, setCreatedCast, setSelectedCurrency, setNeynarSignerModal, setNotificationsNumber, setFeedSelectedTab, setIsCastModalOpen, setUserSelectedLanguage, setTheme, setIsTippingMoxie } = globalStateSlice.actions

export default globalStateSlice.reducer