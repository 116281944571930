import { PrivyProvider } from '@privy-io/react-auth';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import React from "react";
import ReactDOM from "react-dom/client";
import { base } from "viem/chains";
import App from "./App";
import logo from './assets/images/logo.png';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { REACT_APP_PIRVY_APP_ID } from './utils/constants';

import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { store } from './store';
import { NeynarContextProvider, Theme } from "@neynar/react";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient()
const appId = REACT_APP_PIRVY_APP_ID;
let persistor = persistStore(store);

Sentry.init({
  dsn: "https://bf77530921135c24ce45cc8b25b05566@o4507173106614272.ingest.us.sentry.io/4507956210237440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/sys\.wildcard\.lol/, /^https:\/\/gowild-t54me\.ondigitalocean\.app/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

 const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <PrivyProvider
    appId={appId}
    config={{ "defaultChain": base, "supportedChains": [base], "appearance": { "accentColor": "#b4f6a6", "theme": "#222224", "showWalletLoginFirst": false, "logo": logo }, "loginMethods": ["farcaster"], "embeddedWallets": { "createOnLogin": "users-without-wallets", "requireUserPasswordOnCreate": false }, "mfa": { "noPromptOnMfaRequired": false } }}>
    <NeynarContextProvider
      settings={{
        clientId: "fc6fdf91-95d0-4549-8998-0764674ebcde",
        defaultTheme: Theme.Light,
        eventsCallbacks: {
          onAuthSuccess: () => { },
          onSignout: () => { console.log('logout') },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          {/* <PersistGate persistor={persistor}> */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
          {/* </PersistGate> */}
        </Provider>
      </QueryClientProvider>
    </NeynarContextProvider>
  </PrivyProvider >
  // {/* </React.StrictMode> */}
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//   <PrivyProvider
//     appId={appId}
//     config={{ "defaultChain": base, "supportedChains": [base], "appearance": { "accentColor": "#b4f6a6", "theme": "#222224", "showWalletLoginFirst": false, "logo": logo }, "loginMethods": ["farcaster"], "embeddedWallets": { "createOnLogin": "users-without-wallets", "requireUserPasswordOnCreate": false }, "mfa": { "noPromptOnMfaRequired": false } }}>
//     <NeynarContextProvider
//       settings={{
//         clientId: "fc6fdf91-95d0-4549-8998-0764674ebcde",
//         defaultTheme: Theme.Light,
//         eventsCallbacks: {
//           onAuthSuccess: () => { },
//           onSignout: () => { console.log('logout') },
//         },
//       }}
//     >
//       <QueryClientProvider client={queryClient}>
//         <Provider store={store}>
//           {/* <PersistGate persistor={persistor}> */}
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//           {/* </PersistGate> */}
//         </Provider>
//       </QueryClientProvider>
//     </NeynarContextProvider>
//   </PrivyProvider >
//   // {/* </React.StrictMode> */}
// );

// reportWebVitals();

