export default {
  suggestions: {
    list: {
      position: "fixed",
      zIndex: 1000,
      backgroundColor: "white",
      border: "1px solid #333",
      fontSize: 18,
      backgroundColor: "#000",
    },
    item: {
      borderBottom: "1px solid #333",
      "&focused": {
        backgroundColor: "#000",
      },
    },
  },
};