import { usePrivy } from '@privy-io/react-auth'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import BtnNavBar from './components/Sidebars/BtmNavBar'
import LeftSideBar from './components/Sidebars/LeftSideBar'
import RigthSideBar from './components/Sidebars/RigthSideBar'
import CustomModal from './components/UI/CustomModal.jsx'
import Portal from './components/UI/ReactPortal.jsx'
import Snackbar from './components/UI/Snackbar'
import { setNeynarSignerModal, setTheme, setUserSelectedLanguage } from './features/globalStateSlice'
import SignerSignin from './hooks/modal/SignerSignin.jsx'
import BannedState from './pages/Banned/BannedState'
import Funds from './pages/Onboarding/Funds'
import SignInPage from './pages/Signin/SignInPage'
import AddHomeScreen from './pages/Support/AddtoHomeScreen'
import RoutesPublic from './routes/RoutesPublic'
import { getSelectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage } from './utils/constants.js'


const Layout = () => {
    const { pathname } = useLocation()

    const scrollContainerRef = useRef(null);
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const {user, authenticated} = usePrivy()
    const dispatch = useDispatch()
    const { text, show, cast, snackLink } = useSelector(state => state?.app?.snackBar)
    const { openNeynarSignerModal, feedSelectedTab, userSelectedLanguage } = useSelector(state => state.app)

    const handleScroll = (event) => {
        const currentScrollY = window.scrollY;
        const scrollDirection = currentScrollY > lastScrollY ? 'down' : 'up';

        if (scrollDirection === 'down') {
            if (currentScrollY > 100) {
                setShowHeader(false);
            }
        } else {
            if (currentScrollY < lastScrollY) {
                setShowHeader(true);
            }
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        document.body.classList.add('show-scrollbar');
        clearTimeout(window.scrollTimer);
        window.scrollTimer = setTimeout(() => {
            document.body.classList.remove('show-scrollbar');
        }, 2000);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [handleScroll])


    const handleCloseNeynarSignerModal = () => {
        dispatch(setNeynarSignerModal(false))
    }

    useEffect(() => {
        if (localStorage.getItem('theme') == 'dark') {
            document.documentElement.classList.add('dark')
            localStorage.setItem('theme', 'dark')
            dispatch(setTheme('dark'))
            document.getElementsByTagName("body")[0].style.backgroundColor = "#121212";
        } else {
            localStorage.setItem('theme', 'light')
            document.documentElement.classList.remove('dark')
            dispatch(setTheme('light'))
            document.getElementsByTagName("body")[0].style.backgroundColor = "#fff";
        }
    }, [])


    useEffect(() => {
        if(getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency) return
        if (authenticated) {
            setTimeout(() => {
                const currency = {
                    currency_points: {
                        currency_config: {
                            currency: 'WILD',
                            app_primary_color: '#B4F6A5',
                        }
                    }
                }
                setSelectedCurrencyLocalStorage(currency)
                
                document.documentElement.style.setProperty('--color-primary', localStorage.getItem('theme') == 'dark'
                    ? getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
                    : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == 'WILD' ? '#20A402' : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
                )
                document.documentElement.style.setProperty('--color-onPrimary', localStorage.getItem('theme') != 'dark'
                    ? "#FFFFFF"
                    : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == 'WILD' ? '#000' : "#FFFFFF"
                )
            }, 500);
        }
    }, [authenticated]);

    useEffect(() => {
        if(localStorage.getItem('userSelectedLanguage'))
            dispatch(setUserSelectedLanguage(localStorage.getItem('userSelectedLanguage')))
        }
    , [])

    useEffect(() => {
        localStorage.setItem('userSelectedLanguage', userSelectedLanguage)
    }, [userSelectedLanguage])

    if (pathname === '/signin') return <SignInPage />
    if (pathname === '/onboarding/funds') return <Funds />
    if (pathname === '/support') return <AddHomeScreen />
    if (pathname === '/banned') return <BannedState />

    return (
        <div ref={scrollContainerRef} onScroll={handleScroll}  className='grid grid-cols-12 max-w-[1228px] mx-auto lg:-translate-x-[3%] relative'>
            <div className={`${feedSelectedTab === 'cards'?'col-span-1':'col-span-3'} z-[100] sticky top-0 h-fit`}>
                {/* for DESKTOP */}
                <div className='hidden md:block lg:mr-2'>
                    <LeftSideBar mobileView={false} />
                </div>

                {/* for MOBILE */}
                <div className='block md:hidden'>
                    <div className="drawer z-50">
                        <input id="leftSideBar" type="checkbox" className="drawer-toggle" />
                        <div className="drawer-side">
                            <label htmlFor="leftSideBar" aria-label="close sidebar" className="drawer-overlay"></label>
                            <ul className="w-80 z-60 bg-white dark:bg-background min-h-full">
                                <LeftSideBar mobileView={true} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${pathname.includes('/connect') ? "md:col-span-9" : (feedSelectedTab === 'cards')?'md:col-span-11':'md:col-span-6'} col-span-12 md:border-r md:border-l border-gray-300 dark:border-outlineIII ${(pathname==='/tippingarena')?'':'mb-[90px] md:mb-0'} `}>
                {/* <div ref={scrollContainerRef} onScroll={handleScroll} className=' h-full overflow-x-hidden'> */}
                <RoutesPublic showHeader={showHeader} />
                {/* </div> */}

                {show && (
                    <Portal>
                        <div className='fixed bottom-4 left-1/2 transform -translate-x-1/2 z-[1000] w-full max-w-md'>
                            <Snackbar text={text} isCastLink={cast} isLink={snackLink}/>
                        </div>
                    </Portal>
                )}
                
                {!(pathname === '/tippingarena') && <div className='fixed w-full bottom-0 left-0 md:hidden z-50'><BtnNavBar /></div>}
            </div>
            
            {(feedSelectedTab !== 'cards') && !pathname.includes('/connect') &&
                <div className='col-span-3 hidden md:block sticky top-0 h-fit'>
                    <RigthSideBar />
                </div>
            }
            <CustomModal isOpen={openNeynarSignerModal} closeModal={() => handleCloseNeynarSignerModal()} modalPositionTop='50' >
                <SignerSignin closeModal={() => handleCloseNeynarSignerModal()} />
            </CustomModal>
        </div>
    )
}

export default Layout