import { Navigate, Route, Routes } from "react-router-dom"
import BannedState from "../pages/Banned/BannedState"
import CastDetailedPage from "../pages/CastDetailed/CastDetailedPage"
import ChannelPage from "../pages/Channel/ChannelPage"
import FeedPage from "../pages/Feed/FeedPage"
import TicketPage from "../pages/Tickets/TicketPage"
// import InviteMain from "../pages/Invite/InviteMain"
import ConnectPage from "../pages/Connect/ConnectPage"
import Notifications from "../pages/Notifications/Notifications"
import Funds from "../pages/Onboarding/Funds"
import OnchainPage from "../pages/Onchain/OnchainPage"
import PointsPage from "../pages/Points/PointsPage"
import QuestMoreDetails from "../pages/Points/QuestMoreDetails"
import PrivacyPolicyPage from "../pages/PrivacyPolicy/PrivacyPolicyPage"
import ProfilePage from "../pages/Profile/ProfilePage"
import RefreshPage from "../pages/Refresh"
import SignInPage from "../pages/Signin/SignInPage"
import AddHomeScreen from "../pages/Support/AddtoHomeScreen"
import WrongBrowser from "../pages/Support/WrongBrowser"
import TippingArena from "../pages/TippingArena/TippingArena"
import WalletPage from "../pages/Wallet/WalletPage"
import { isDesktopDevice } from "../utils/helper"
import PrivateRoute from "./PrivateRoute"

const RoutesPublic = ({ showHeader }) => {
    const isDesktop = isDesktopDevice();
    return (
        <Routes>
            <Route path={'/signin'} element={<SignInPage />} />
            <Route path="/support" element={<AddHomeScreen />} />
            <Route path="/support/wrongbrowser" element={<WrongBrowser />} />
            {/* <Route exact path={`/join`} element={<JoinInvite />} /> */}
            {/* <Route exact path={`/join/:fid`} element={<JoinInvite />} /> */}
            <Route path="/refresh" element={<RefreshPage />} />
            <Route exact path="/" element={<PrivateRoute />}>
                <Route exact path={'/'} element={<Navigate to='/feed' />} />
                <Route exact path={'/feed'} element={<FeedPage showHeader={showHeader} />} />
                <Route exact path={'/feed/:tab'} element={<FeedPage showHeader={showHeader} />} />
                <Route path={'/notifications'} element={<Notifications />} />
                <Route path={'/tickets'} element={<TicketPage showHeader={showHeader} />} />
                <Route path={'/tickets/:tab'} element={<TicketPage showHeader={showHeader} />} />
                <Route path={'/points'} element={<PointsPage showHeader={showHeader} />} />
                <Route path={'/points/:tab'} element={<PointsPage showHeader={showHeader} />} />
                <Route path={'/wallet'} element={<WalletPage showHeader={showHeader} />} />
                <Route path={'/wallet/:tab'} element={<WalletPage showHeader={showHeader} />} />
                <Route path={"/onboarding/funds"} element={<Funds />} />
                <Route exact path={`/banned`} element={<BannedState />} />
                {/* <Route exact path={`/join`} element={<JoinInvite />} /> */}
                <Route path="/quests/:id" element={<QuestMoreDetails />} />
                <Route exact path={'/profile/:username'} element={<ProfilePage showHeader={showHeader} />} />
                <Route path={'/profile/:username/:tab'} element={<ProfilePage showHeader={showHeader} />} />
                <Route path={'/cast/:hash/:fid'} element={<CastDetailedPage />} />
                <Route path="/channel/:channelId" element={<ChannelPage />} />
                <Route path="/channel/:channelId/:tab" element={<ChannelPage />} />
                <Route path="/connect/:id" element={<ConnectPage />} />
                <Route path={'/onchain'} element={<OnchainPage showHeader={showHeader} />} />

                <Route path={'/onchain'} element={<OnchainPage showHeader={showHeader} />} />

                {/* <Route exact path='/invite/:fid' element={<InviteMain />} /> */}
                {
                    !isDesktop && <Route path={'/tippingarena'} element={<TippingArena />} />
                }
            </Route>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
    )
}

export default RoutesPublic
