import { useNeynarContext } from '@neynar/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setNeynarSignerModal } from '../features/globalStateSlice';
import { displaySnackbar } from '../features/thunkMiddleware';
import { checkSigner, createFrames, deleteCast, followUserNeynar, likeReactionNeynar, makeCast, makeScheduleCast, reacastReactionNeynar, unFollowUserNeynar, unLikeReactionNeynar, unRecastReactionNeynar, updateSigner } from '../services/api';

export const formatForCastAction = ({text, embeds, channelUrl = '', hash, fid} = {}) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (channelUrl === 'custom_global') {
        channelUrl = '';
    }

    // Extract links from the text
    const links = text.match(urlRegex) || [];

    // Structure links as objects and combine with existing embeds
    const linkEmbeds = links.map(link => ({ url: link }));
    const allEmbeds = [...linkEmbeds, ...(embeds || [])];

    const newText = text?.replace(/@\[(.*?)\]\(\d+\)/g, '@$1')?.replace(/\/\[(.*?)\]/g, '/$1');

    const body = {
        text: newText,
        embeds: allEmbeds,
        parent: hash,
        channel_id: channelUrl,
        parent_author_fid: fid
    };

    return body
}

const useCastAction = () => {
    const { user } = useNeynarContext();
    const dispatch = useDispatch();

    const handleSignerValidate = useCallback(async () => {
        try {
            const response = await checkSigner();
            const isSigner = response.data?.success;

            if (isSigner) {
                dispatch(setNeynarSignerModal(false));
                return isSigner;
            }
            if (!isSigner && user?.signer_uuid) {
                const res = await updateSigner(user.signer_uuid);
                const isSignerUpdated = res.data?.success;
                return isSignerUpdated;
            } else {
                dispatch(setNeynarSignerModal(true));
                dispatch(displaySnackbar('Please set up your signer'));
                return false;
            }

        } catch (error) {
            console.error('Error checking or updating signer:', error);
            return false;
        }
    }, [dispatch, user]);

    const submitCast = useCallback(async (text, embeds, channelUrl = '', hash, fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const body = formatForCastAction({text, embeds, channelUrl, hash, fid});

        try {
            const response = await makeCast(body);
            return response;
        } catch (error) {
            console.error('Error submitting cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const scheduleCast = useCallback(async (text, embeds, channelUrl = '', hash, fid, { timestamp, repeat, repeat_count }) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;
        console.log(text)
        const body = {
            cast_request: formatForCastAction({text, embeds, channelUrl, hash, fid}),
            timestamp: timestamp,
            repeat: repeat,
            repeat_count: repeat_count
        };

        try {
            const response = await makeScheduleCast(body);
            return response;
        } catch (error) {
            console.error('Error submitting cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const removeCast = useCallback(async (hash) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const body = { cast_hash: hash };

        try {
            const response = await deleteCast(body);
            return response;
        } catch (error) {
            console.error('Error removing cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const likeCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await likeReactionNeynar(target, target_author_fid)
        return response
    }, [handleSignerValidate]);

    const unLikeCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await unLikeReactionNeynar(target, target_author_fid)
        return response
    }, [handleSignerValidate]);

    const recastCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await reacastReactionNeynar(target, target_author_fid)
        return response
    }, [handleSignerValidate]);

    const unRecastCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await unRecastReactionNeynar(target, target_author_fid)
        return response
    }, [handleSignerValidate]);

    const followUser = useCallback(async (target_ids) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await followUserNeynar(target_ids)
        return response
    }, [handleSignerValidate]);

    const unFollowUser = useCallback(async (target_ids) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await unFollowUserNeynar(target_ids)
        return response
    }, [handleSignerValidate]);

    const handleFrame = useCallback(async (body) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await createFrames(body)
        return response

    }, [handleSignerValidate]);

    return { submitCast, removeCast, likeCast, unLikeCast, followUser, unFollowUser, handleFrame, recastCast, unRecastCast, scheduleCast };

};

export default useCastAction;
