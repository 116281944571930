import { NeynarAuthButton, SIWN_variant, useNeynarContext } from '@neynar/react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNeynarSignerModal } from '../../features/globalStateSlice';

const SignerSignin = () => {
    // const client_id = 'fc6fdf91-95d0-4549-8998-0764674ebcde';
    // const neynar_login_url = "https://app.neynar.com/login";

    const { user } = useNeynarContext();
    const dispatch = useDispatch();

    useEffect(() => {
        const scriptId = "siwn-script";
        let script = document.getElementById(scriptId);

        if (!script) {
            script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://neynarxyz.github.io/siwn/raw/1.2.0/index.js";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }

        return () => {
            if (script) {
                document.body.removeChild(script);
            }

            const button = document.getElementById("siwn-button");
            if (button && button.parentElement) {
                button.parentElement.removeChild(button);
            }
        };
    }, []);

    // const getButton = useCallback(() => (
    //     <div
    //         className="neynar_signin"
    //         data-client_id={client_id}
    //         data-variant="warpcast"
    //         data-neynar_login_url={neynar_login_url}
    //         data-success-callback="onSignInSuccess"
    //     ></div>
    // ), [client_id, neynar_login_url]);
    const isSigner = !!user?.signer_uuid

    return (
        <div className='relative flex justify-start flex-col gap-8  items-center w-full bg-background h-80'>
            {!isSigner && <h1 className='prose-TitleLarge max-w-80  text-center mt-12'>
                We need you to sign in with Neynar in order to write to Farcaster</h1>}
            <button disabled={isSigner} className={`w-fit absolute bottom-10 ${!isSigner && 'bg-primary'} h-fit py-2 px-5 flex justify-center items-center prose-TitleLarge rounded-sm text-black text-background hover:bg-onBackground`}>
                <NeynarAuthButton
                    icon={false}
                    label='login'
                    variant={SIWN_variant.FARCASTER}
                    className={`flex flex-col justify-center ${isSigner && 'w-16'} items-center prose-TitleLarge`} />
            </button>
            {isSigner &&
                <button
                    onClick={() => dispatch(setNeynarSignerModal(false))}
                    className='bg-primary bottom-10 absolute text-onPrimary  flex justify-center items-center  prose-TitleLarge px-3 py-1 w-fit lg:w-[400px]'>
                    continue to app
                </button>}
        </div>
    );
};

export default SignerSignin;
