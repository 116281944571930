import { ChatCircleDots, Coins, DotsThreeVertical, Heart, Link, LinkSimple, Pen, Repeat, Trash } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import powerbadgeSvg from '../../../assets/svg/powerbadge.svg';
import { displaySnackbar } from '../../../features/thunkMiddleware';
import useCastAction from '../../../hooks/signer';
import { getCurrentUserProfile, pinCast, unPinCast } from '../../../services/api';
import { cleanMentions, converDateTimeStr, highlightWord, timeSince } from '../../../utils/helper';
import CastModal from '../../Modals/CastModal';
import TipModal from '../../Modals/TipModal';
import CustomModal from '../../UI/CustomModal';
import FrameCard from './components/card/FrameCard';
import ProfileHoverCard from '../../ProfileHoverCard';

import pinSvgBlack from '../../../assets/svg/pin_black.svg';
import pinSvgWhite from '../../../assets/svg/pin_white.svg';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';
import ChannelHoverCard from '../../ChannelHoverCard';

const isImage = (url) => {
    return url?.startsWith('https://supercast.com/') || url?.startsWith('https://imagedelivery.net/') || url?.startsWith('https://static-wildcard-prod.nyc3.cdn.digitaloceanspaces.com/') || url?.startsWith('https://media.tenor.com/') || url?.startsWith('http://res.cloudinary.com/') || url?.startsWith('https://res.cloudinary.com/') || url?.startsWith('https://i.imgur.com/')
};

const CastCards = ({ castData = {}, isRepliesCast, i, refetchCasts, fromReplyCastModal, parentCast, fromNotificationReply, setIsCastModalOpen = () => { }, fromCastDetails, fromMentionNotification, fromEmbeds = false, isLoadingScoreFollowing, fromSchedule }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { removeCast, likeCast, unLikeCast, recastCast, unRecastCast } = useCastAction()
    const { currentUser, userSelectedLanguage, theme } = useSelector(state => state.app)

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency

    const [textHidden, setTextHidden] = useState(true);
    const [showTipModal, setShowTipModal] = useState(false);
    const [showReplyCastModal, setShowReplyCastModal] = useState(false);
    const [showImgModal, setShowImgModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const [currLikes, setCurrLikes] = useState(castData?.likes);
    const [currRecasts, setCurrRecasts] = useState(castData?.recasts);
    const [hasLiked, setHasLiked] = useState(castData?.user_reaction?.has_liked);
    const [hasRecasted, setHasRecasted] = useState(castData?.user_reaction?.has_recasted);
    const [framesData, setFramesData] = useState([])
    const [showCastOptions, setShowCastOptions] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const [showRecastOptions, setshowRecastoptions] = useState(false);
    const [showQuoteCastModal, setShowQuoteCastModal] = useState(false);

    const { data: profileData, isLoading: isProfileLoading, refetch: profileDataRefetch } = useQuery({
        queryKey: ['getCurrentUserProfile'],
        queryFn: getCurrentUserProfile,
    });
    const { cast, farcaster_user, replies, channel, tips_24h_all, frames } = castData || {};
    const tipsData = tips_24h_all?.filter(token => token?.currency === selectedCurrency)[0];

    const isCastDetailed = pathname === `/cast/${cast?.id}/${farcaster_user?.fid}`;
    let castText = cast?.translations?.translations ? cast?.translations?.translations[userSelectedLanguage] : cast?.translations?.original_text;
    castText = castText || cast?.body;
    castText = cleanMentions(castText);

    const hasNestedCast = cast?.embeds != null;
    const castEmbedsImgs = cast?.url?.filter(url => isImage(url)) || [];
    const nestedCastEmbeds = cast?.embeds ?? [];

    const updateRecastValue = () => {
        if (hasRecasted)
            setCurrRecasts(prev => prev - 1)
        else
            setCurrRecasts(prev => prev + 1)
        setHasRecasted(prev => !prev)
    }

    const handleShowRecastOptions = () => {
        setshowRecastoptions(prev => !prev);
    }

    const handleRecastOptions = (e, type) => {
        setshowRecastoptions(prev => !prev);
        e.stopPropagation()
        if (type === 'quote') {
            handleOpenQuoteCastModal()
        }
        if (type === 'recast') {
            handleReCast(cast?.id, farcaster_user?.fid)
            updateRecastValue();

        }
    }

    const handleTextHidden = (e) => {
        e.stopPropagation();
        setTextHidden((prev) => !prev);
    }

    const handleCastDetailed = (e) => {
        e.stopPropagation();
        navigate(`/cast/${cast?.id}/${farcaster_user?.fid}`)
    }

    const handleUserProfileNavigate = (e) => {
        e.stopPropagation();
        navigate(`/profile/${farcaster_user?.username}`)
    }

    const handleOpenTipModal = (e) => {
        e.stopPropagation();
        if (profileData?.data?.farcaster_user?.fid === farcaster_user?.fid) return
        setShowTipModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseTipModal = () => {
        setShowTipModal(false)
    }

    const handleOpenReplyCastModal = (e) => {
        e.stopPropagation();
        setShowReplyCastModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseReplyCastModal = () => {
        setShowReplyCastModal(false)
    }
    const handleOpenQuoteCastModal = () => {
        setShowQuoteCastModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseQuoteCastModal = () => {
        setShowQuoteCastModal(false)
    }

    const handleLikeReaction = (e) => {
        e.stopPropagation();
        if (hasLiked) {
            unLikeCast(cast?.id, farcaster_user?.fid)
        } else {
            likeCast(cast?.id, farcaster_user?.fid)
        }
    }

    const handleReCast = (hash, fid) => {
        if (hasRecasted) {
            unRecastCast(hash, fid)
        } else {
            recastCast(hash, fid)
        }
    }

    const handleOpenImageModal = (e, url) => {
        e.stopPropagation();
        setSelectedImage(url);
        setShowImgModal(prev => !prev);
    }
    const handleCloseImageModal = () => {
        setShowImgModal(false);
    }

    const handleCopyCastUrl = () => {
        const isLocalHost = window?.location?.host?.includes('localhost:');
        const url = `${window?.location?.host}/cast/${cast?.id}/${farcaster_user?.fid}`
        const copyValue = isLocalHost ? url : 'https://' + url;
        navigator.clipboard.writeText(copyValue)
            .then(() => {
                dispatch(displaySnackbar('Copied!'))
            })
            .catch((error) => console.error('Failed to copy:', error));
    }

    const handleDeleteCast = async () => {
        setTimeout(() => {
            setIsDeleted(true);
        }, 1000)
        try {
            const response = await removeCast(cast?.id);
            const result = response?.data.success
            if (result) {
                dispatch(displaySnackbar('Cast deleted successfully'))
            }
            else
                dispatch(displaySnackbar('Failed to delete'))
        }
        catch (err) {
            console.log(err);
            dispatch(displaySnackbar('Failed to delete'))
        }
        if (pathname === `/cast/${cast?.id}/${farcaster_user?.fid}`) {
            navigate(-1);
        }

    }

    useEffect(() => {
        document.addEventListener('click', () => {
            setShowCastOptions(false);
        })

        return document.removeEventListener('click', () => {
            setShowCastOptions(false);
        })
    }, [])

    useEffect(() => {
        setFramesData(frames)
    }, [frames])

    const pinCastToProfile = (type) => {
        if (type === 'pin') {
            pinCast(cast?.id).then((res) => {
                dispatch(displaySnackbar('Cast pinned successfully'))
                refetchCasts()
            })
            return
        }
        if (type === 'unpin') {
            unPinCast().then((res) => {
                dispatch(displaySnackbar('Cast unpinned successfully'))
                refetchCasts()
            })
            return
        }
    }

    const handleClose = () => {
        setShowCastOptions(false);
    }

    useEffect(() => {
        document.addEventListener('scroll', handleClose);
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('scroll', handleClose);
            document.removeEventListener('click', handleClose);
        };
    }, []);

    const showThreaded = cast?.children?.length > 0 && !isCastDetailed && !fromMentionNotification && !fromEmbeds && !fromNotificationReply && !fromReplyCastModal;

    return (
        <>
            <div key={i} onClick={(e) => handleCastDetailed(e)} className={`${isDeleted ? 'hidden' : 'flex'} ${fromNotificationReply ? 'flex-col' : ''} gap-x-4 pt-4 border-gray-200 dark:border-outlineIII relative px-5 w-full pb-2 cursor-pointer ${!(fromReplyCastModal || fromCastDetails || fromNotificationReply) ? 'lg:hover:bg-gray-50 dark:lg:hover:bg-surface/40 border-b flex-col' : 'flex-row'} ${(showThreaded || fromSchedule) ? "border-b-0" : ''}`}>
                {!fromNotificationReply && <>
                    {cast?.recast_details && !fromReplyCastModal && <div className='flex items-center gap-2 pl-6'>
                                <Repeat size={16} weight="bold" className='fill-subTxtOnLightBg dark:fill-onBackgroundII' />
                                <p className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground'> 
                                    <ProfileHoverCard username={cast?.recast_details?.user?.username} data={cast?.recast_details?.user}>
                                        {cast?.recast_details?.user?.display_name}
                                    </ProfileHoverCard>
                                    <span className='text-subTxtOnLightBg dark:text-onBackgroundII text-xs'> recasted</span>
                                </p>
                            </div>}
                    <div className={`flex gap-2 ${(fromReplyCastModal || fromCastDetails) ? 'items-start' : 'items-center'}`}>
                        <div onClick={(e) => handleUserProfileNavigate(e)} className='min-h-10 min-w-10 h-fit cursor-pointer'>
                            <ProfileHoverCard username={farcaster_user?.username} data={farcaster_user}>
                                <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="cast" className={`size-10 object-cover rounded-full bg-background ${isRepliesCast ? "border-2 border-outline" : ""}`} />
                            </ProfileHoverCard>
                        </div>

                        <div className={`gap-4 w-full ${(fromCastDetails || fromReplyCastModal) ? 'hidden' : 'flex'}`}>
                            <div className='w-full flex items-center justify-between'>
                                <div onClick={(e) => handleUserProfileNavigate(e)} className='flex items-center flex-wrap gap-1 prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>
                                    <ProfileHoverCard username={farcaster_user?.username} data={farcaster_user}>
                                        {farcaster_user?.display_name}
                                    </ProfileHoverCard>
                                    {
                                        farcaster_user?.power_badge &&
                                        <span>
                                            <img src={powerbadgeSvg} alt="powerbadge"
                                                className="w-4 h-4" />
                                        </span>
                                    }
                                    <ProfileHoverCard username={farcaster_user?.username} data={farcaster_user}>
                                        <span className='prose-BodyMedium text-txtOnLightBg dark:text-onBackground'>@{farcaster_user?.username}</span>
                                    </ProfileHoverCard>
                                    {!fromSchedule && <><span className='size-1 bg-white rounded-full' />
                                        <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{timeSince(cast?.timestamp)}</span></>}
                                    {fromMentionNotification && <p className='bg-gray-300 dark:bg-surface rounded-full px-2 py-[2px] prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII'>Tagged You</p>}
                                </div>
                                {!fromSchedule && <div className='flex gap-4 items-center'>

                                    <div className='   rounded-full p-2 transition duration-300 relative'
                                        role='button'
                                        onClick={(e) => { e.stopPropagation(); setShowCastOptions(prev => !prev) }}
                                    >
                                        <DotsThreeVertical size={16} weight="bold" className='fill-black dark:fill-white' />
                                        <div className={`absolute z-20 top-8 right-4 w-36 bg-background/10 backdrop-blur-sm p-1 flex flex-col gap-2 rounded-md transition duration-200 prose-BodyLarge ${showCastOptions ? '' : 'scale-0'}`}>
                                            <div role='button' onClick={handleCopyCastUrl} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><Link size={16} /> Copy Link</div>
                                            {!castData?.pinned
                                                ? <div role='button' onClick={() => pinCastToProfile('pin')} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><img src={theme == 'dark' ? pinSvgWhite : pinSvgBlack} alt='pinned' className='size-4 fill-white' />Pin cast to profile</div>
                                                : <div role='button' onClick={() => pinCastToProfile('unpin')} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><img src={theme == 'dark' ? pinSvgWhite : pinSvgBlack} alt='pinned' className='size-4 fill-white' />Unpin cast from profile</div>
                                            }
                                            {farcaster_user?.username == currentUser?.username && !fromReplyCastModal &&
                                                <div role='button' onClick={handleDeleteCast} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1 text-error'><Trash size={16} /> Delete Cast</div>}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </>
                }

                {fromNotificationReply && <div className='flex flex-col min-w-[375px]'>
                    <div className='flex gap-2'>
                        <div>
                            {fromNotificationReply?.actors?.slice(0, 5)?.map((actor, index) => (
                                <ProfileHoverCard username={actor?.username} data={actor}>
                                    <img
                                        onClick={(e) => { e.stopPropagation(); navigate(`/profile/${actor?.username}`) }}
                                        key={index}
                                        src={actor?.pfp_url}
                                        className="size-12 min-w-12 object-cover rounded-full cursor-pointer"
                                        alt="profile img"
                                    />
                                </ProfileHoverCard>
                            ))}
                        </div>
                        <div className='flex flex-col -translate-y-1 '>
                            <div className='prose-HeaderSmall text-txtOnLightBg dark:text-onBackground flex items-center gap-1 flex-wrap'>
                                <ProfileHoverCard username={fromNotificationReply?.actors[0]?.username} data={fromNotificationReply?.actors[0]}>
                                    {fromNotificationReply?.actors[0]?.display_name}
                                    <span className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII'>@{fromNotificationReply?.actors[0]?.username}</span>
                                </ProfileHoverCard>
                                <div className='size-1 bg-onBackground rounded-full mx-1' />
                                <span className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII'>{converDateTimeStr(fromNotificationReply?.created_at)}</span>
                                <p className='bg-gray-300 dark:bg-surface rounded-full px-2 py-[2px] prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII'>Replied to You</p>
                            </div>
                            <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundIII '>{parentCast?.body?.slice(0, 100)}</p>
                            <p className='prose-LabelMedium text-subTxtOnLightBg dark:text-onBackgroundII'>replying to <span className=' text-primary cursor-pointer' onClick={() => navigate(`/profile/${fromNotificationReply?.target_cast_hash_details?.farcaster_user?.username}`)}>@{fromNotificationReply?.target_cast_hash_details?.farcaster_user?.username}</span></p>
                        </div>
                    </div>
                </div>}
                {
                    showThreaded && <div className='bg-surface h-full absolute w-[1px] top-5 left-10' />
                }

                <div className={`${showThreaded ? 'pl-12' : ''} w-full cursor-pointer`}>
                    {!fromNotificationReply &&
                        <div className={`gap-4 w-full ${(fromCastDetails || fromReplyCastModal) ? 'flex' : 'hidden'}`}>
                            <div className='w-full flex items-center justify-between'>
                                <div onClick={(e) => handleUserProfileNavigate(e)} className='flex items-center flex-wrap gap-1 prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>
                                    {farcaster_user?.display_name}
                                    {
                                        farcaster_user?.power_badge &&
                                        <span>
                                            <img src={powerbadgeSvg} alt="powerbadge"
                                                className="w-4 h-4" />
                                        </span>
                                    }
                                    <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackground'>@{farcaster_user?.username}</span>
                                    {!fromSchedule && <><span className='size-1 bg-background dark:bg-white rounded-full' />
                                        <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{timeSince(cast?.timestamp)}</span></>}
                                    {fromMentionNotification && <p className='bg-gray-300 dark:bg-surface rounded-full px-2 py-[2px] prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII'>Tagged You</p>}
                                </div>
                                {!fromSchedule && <div className='flex  items-center'>
                                    {castData?.pinned &&
                                        <div className='flex items-center gap-1'>
                                            <img src={theme == 'dark' ? pinSvgWhite : pinSvgBlack} alt='pinned' className='size-4 fill-white dark:fill-black' />
                                            <div className='prose-BodySmall text-subTxtOnLightBg dark:text-onBackgroundII'>Pinned</div>
                                        </div>}
                                    <div className='   rounded-full p-2 transition duration-300 relative'
                                        role='button'
                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowCastOptions(prev => !prev) }}
                                    >
                                        <DotsThreeVertical size={16} weight="bold" className='fill-black dark:fill-white' />
                                        <div className={`absolute z-10 top-8 right-4 w-36 bg-background/10 backdrop-blur-sm p-1 flex flex-col gap-2 rounded-md transition duration-200 prose-BodyLarge ${showCastOptions ? '' : 'scale-0'}`}>
                                            <div role='button' onClick={handleCopyCastUrl} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><Link size={16} /> Copy Link</div>
                                            {!castData?.pinned
                                                ? <div role='button' onClick={() => pinCastToProfile('pin')} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><img src={theme == 'dark' ? pinSvgWhite : pinSvgBlack} alt='pinned' className='size-4 fill-white' />Pin cast to profile</div>
                                                : <div role='button' onClick={() => pinCastToProfile('unpin')} className='flex items-center gap-2 text-txtOnLightBg dark:text-onBackground hover:bg-white/20 rounded-sm p-1'><img src={theme == 'dark' ? pinSvgWhite : pinSvgBlack} alt='pinned' className='size-4 fill-white' />Unpin cast from profile</div>
                                            }
                                            {farcaster_user?.username == currentUser?.username && !fromReplyCastModal && !fromEmbeds &&
                                                <div role='button' onClick={handleDeleteCast} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1 text-error'><Trash size={16} /> Delete Cast</div>}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    }

                    <div>
                        <div className={`prose-BodyLarge text-[15px] text-txtOnLightBg dark:text-onBackground flex-wrap text-wrap md:w-full ${fromNotificationReply ? 'ps-14' : 'mt-3'}`}>
                            {fromReplyCastModal || fromNotificationReply || fromMentionNotification ? <div>{castText?.length < 100 ? castText : castText.slice(0, 200)} {castText?.length == 0 || castText?.length < 100 ? "" : fromMentionNotification ? <p className='text-maintextColorOnLightBg/80 hover:underline transition duration-300'>read more...</p> : "..."}</div> :
                                <> {isCastDetailed ? highlightWord(castText, true, navigate) :
                                    textHidden && castText?.length > 320
                                        ? <div>
                                            {highlightWord(castText?.slice(0, 320), true, navigate)}
                                            <span onClick={handleTextHidden} className="prose-BodySmall text-onBackgroundIII cursor-pointer ms-1">...read more</span>
                                        </div>
                                        : <div>
                                            {highlightWord(castText, true, navigate)}
                                            {castText?.length > 320 && <span onClick={handleTextHidden} className="prose-BodySmall text-onBackgroundIII cursor-pointer ms-1">...collapse</span>}
                                        </div>
                                }
                                </>
                            }
                        </div>
                    </div>
                    <div className='flex justify-start flex-col w-full mt-2'>
                        <div className='w-full'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const data = cast?.url_metadatas[url] || null;
                                    const isYoutubeUrl = url.includes('youtube.com/watch?v=') || url.includes('youtu.be');
                                    if (data == null) return;
                                    if (data?.is_frame) return;
                                    if (isImage(url)) return;
                                    if (isYoutubeUrl) return <ReactPlayer key={url} url={url} muted controls={true} width='100%' />
                                    if (data?.content_type === 'text/html' || data?.content_type === '')
                                        return (
                                            <div key={url} className='border dark:border-outlineII bg-white dark:bg-background p-5 rounded-md relative mb-2' onClick={(e) => e.stopPropagation()}>
                                                <a href={url} target="_blank" rel="noopener noreferrer" className='absolute inset-0 size-full'></a>
                                                <div className={`flex gap-3 ${!data?.image && !data?.title ? 'items-center' : 'items-start'}`}>
                                                    {data?.image && <img src={data?.image} alt={data?.title} className='size-20 dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                                                    {!data?.image && <LinkSimple size={24} className='fill-black dark:fill-white' />}
                                                    <div className='flex flex-col gap-3'>
                                                        {data?.title && <h3 className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground'>{data?.title}</h3>}
                                                        <p className='prose-BodySmall text-txtOnLightBg dark:text-white'>{data?.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                })
                            }
                        </div>
                        <div className='w-full flex gap-2'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const metaData = cast?.url_metadatas ? cast?.url_metadatas[url] : null;
                                    if (metaData?.media_type === 'video') {
                                        return <div key={index} className='relative w-fit md:h-[400px] h-[300px] mb-2 overflow-hidden'>
                                            <ReactPlayer key={index} url={url} autoPlay playing muted controls={true} width='fit' height='100%' />
                                        </div>
                                    }
                                    if (isImage(url)) return (
                                        <div key={index} className={`relative ${fromReplyCastModal ? 'max-h-[100px]' : 'max-h-[300px] md:max-h-[70vh]'} mb-2`}>
                                            <img src={url} alt="embed" className='h-full object-cover rounded-lg cursor-pointer' onClick={(e) => handleOpenImageModal(e, url)} />
                                        </div>
                                    )
                                    if (metaData == null) return;
                                    if (metaData?.media_type === 'img' || isImage(url))
                                        return (
                                            <div key={index} className={`relative ${fromReplyCastModal ? 'max-h-[100px]' : 'max-h-[200px] md:max-h-[70vh]'} mb-2`}>
                                                <img src={url} alt="embed" className='h-full object-cover rounded-lg cursor-pointer' onClick={(e) => handleOpenImageModal(e, url)} />
                                            </div>
                                        )

                                })
                            }
                        </div>
                        {!fromReplyCastModal && framesData && framesData?.length > 0 &&
                            <div onClick={e => e.stopPropagation()} className={`flex ${castEmbedsImgs?.length > 1 ? 'w-1/2' : 'w-full'}  max-w-[100%] border dark:border-outlineII rounded-lg flex-row overflow-hidden border-default justify-around mb-2`}>
                                {framesData?.map((embed, index) => (<React.Fragment key={index}>  <FrameCard embed={embed} cast={cast} castUser={farcaster_user} setFramesData={setFramesData} /> </React.Fragment>)
                                )}
                            </div>
                        }
                    </div>

                    {!fromReplyCastModal && hasNestedCast && <div className='mb-2'>
                        <div className="flex flex-col mb-2 gap-2 border-default w-full rounded-lg">
                            {
                                nestedCastEmbeds?.map((embeds, idx) => (
                                    <CastCards castData={embeds} key={idx} refetchCasts={refetchCasts} selectedCurrency={selectedCurrency} fromNotificationReply={fromNotificationReply} setIsCastModalOpen={setIsCastModalOpen} fromEmbeds fromSchedule={fromSchedule} />
                                    // <EmbeddedCast data={embeds} key={idx} />
                                ))
                            }
                        </div>
                    </div>
                    }

                    {!fromReplyCastModal && !fromEmbeds && <>
                        {!isRepliesCast && channel?.name &&
                            <div className='w-fit'>
                                <ChannelHoverCard channelid={channel?.id} data={channel} >
                                    <span
                                        role='button'
                                        onClick={(e) => { e.stopPropagation(); navigate(`/channel/${channel?.id}`) }}
                                        className='prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII text-center px-3 py-1 rounded-full bg-background/5 dark:bg-white/5 hover:bg-background/10 dark:hover:bg-outline'>{channel?.name}</span>
                                </ChannelHoverCard>
                            </div>
                        }

                        <div className='flex justify-between mt-2 items-center mb-2 w-full'>
                            <div className='flex items-center gap-3 md:gap-3'>
                                <div onClick={handleOpenReplyCastModal} className='flex items-center group'>
                                    <span className='rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <ChatCircleDots size={20} className={`group-hover:fill-btnColorOnLightBg dark:group-hover:fill-primary fill-black dark:fill-[#ffffffa6]`} />
                                    </span>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg dark:group-hover:text-primary'>{replies}</span>
                                </div>
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    handleShowRecastOptions();
                                }}
                                    className='flex items-center group cursor-pointer relative'
                                >
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        < Repeat size={20}
                                            className={`group-hover:fill-btnColorOnLightBg dark:group-hover:fill-primary  ${hasRecasted ? "fill-primary" : "fill-black dark:fill-[#FFFFFFA6]"}`} />
                                    </span>
                                    <div className={`${showRecastOptions ? 'scale-1' : 'scale-0'} absolute z-20 bottom-5 left-4 transition duration-300 bg-background dark:bg-white/10 backdrop-blur-sm p-2 rounded-sm`}>
                                        <div role='button' onClick={(e) => { handleRecastOptions(e, 'recast') }} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1'><Repeat size={16} />Recast</div>
                                        <div role='button' onClick={(e) => { handleRecastOptions(e, 'quote') }} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1'><Pen size={16} />Quote</div>
                                    </div>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg dark:group-hover:text-primary'>{parseInt(currRecasts)}</span>
                                </div>
                                <div onClick={(e) => {
                                    handleLikeReaction(e)
                                    if (hasLiked)
                                        setCurrLikes(prev => prev - 1)
                                    else
                                        setCurrLikes(prev => prev + 1)
                                    setHasLiked(prev => !prev)
                                }}
                                    className='flex items-center group cursor-pointer'
                                >
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <Heart
                                            size={20}
                                            weight={!hasLiked ? "regular" : "fill"}
                                            className={`group-hover:fill-btnColorOnLightBg dark:group-hover:fill-primary ${hasLiked ? "fill-btnColorOnLightBg dark:fill-primary" : "fill-black dark:fill-[#FFFFFFA6]"}`}
                                        />
                                    </span>
                                    {/* <span className='md:  rounded-full transition duration-[450ms]'>
                                       <Heart hasLiked={hasLiked} />
                                    </span> */}
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg dark:group-hover:text-primary'>{parseInt(currLikes)}</span>
                                </div>
                                {selectedCurrency != 'WILD' && <div className='flex items-center group' onClick={handleOpenTipModal}>
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <img src={tipsData?.image || logo} alt="logo" className='max-w-5 h-5 aspect-square rounded-full' />
                                    </span>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg dark:group-hover:text-primary'>{isLoadingScoreFollowing ? <div className='h-4 w-8 bg-surface rounded-sm animate-pulse' /> : tipsData?.display_value === "0" ? 0 : tipsData?.display_value}</span>
                                </div>
                                }
                            </div>
                            {profileData?.data?.farcaster_user?.fid !== farcaster_user?.fid &&
                                <div onClick={handleOpenTipModal} className='prose-BodyMedium group flex items-center'>
                                    <span className='group-hover:fill-primary rounded-full p-[7px] transition duration-[450ms] text-txtOnLightBg dark:text-onBackgroundII'><Coins size={20} weight={theme == 'dark' ? 'regular' : 'fill'} className='group-hover:fill-primary' /></span>
                                    <span className="group-hover:text-primary text-txtOnLightBg dark:text-onBackgroundII">Tip</span>
                                </div>
                            }
                        </div>
                    </>
                    }
                </div>

                <CustomModal isOpen={showImgModal} closeModal={handleCloseImageModal} modalPositionTop={'50'} translatePosition={'translate(-50%, -50%)'}>
                    <div className='flex justify-center items-center'>
                        <img src={selectedImage} alt="embed" className="w-full h-full object-contain md:max-h-[90vh] md:max-w-[90vw]" onClick={(e) => { e.stopPropagation(); handleCloseImageModal() }} />
                    </div>
                </CustomModal>
            </div >
            {
                showReplyCastModal && <CustomModal isOpen={showReplyCastModal} closeModal={handleCloseReplyCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, -50%)'}>
                    <CastModal closeModal={handleCloseReplyCastModal} castDetails={castData} refetch={refetchCasts} fromReplyCastModal={true} />
                </CustomModal>
            }
            {
                showQuoteCastModal && <CustomModal isOpen={showQuoteCastModal} closeModal={handleCloseQuoteCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, -50%)'}>

                    <CastModal closeModal={handleCloseQuoteCastModal} castDetails={castData} refetch={refetchCasts} isQuoteCast={true} updateRecastValue={updateRecastValue} />
                </CustomModal>
            }
            {
                showTipModal && <CustomModal isOpen={showTipModal} closeModal={handleCloseTipModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <TipModal selectedTip={castData} closeModal={handleCloseTipModal} setShowTipModal={setShowTipModal} refetch={refetchCasts} refetchUserdetail={profileDataRefetch} />
                </CustomModal>
            }
            {
                showThreaded && cast?.children?.map((cast, i) => (
                    <CastCards castData={cast} i />
                ))
            }

        </>
    )
}

export default CastCards