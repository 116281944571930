import { usePrivy } from '@privy-io/react-auth';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuthMiddleware from '../hooks/useAuthMiddleware';

const PrivateRoute = () => {
    const { ready, authenticated } = usePrivy();
    const location = useLocation();


    useAuthMiddleware();

    if (!ready) {
        return null
    }

    if (ready && authenticated) {

        return <Outlet />;
    }

    return <Navigate to={`/signin?redirect=${location.pathname}${location.search}`} />;
};

export default PrivateRoute;
